






import BaseRouter from '@/components/BaseRouter.vue';
import SidebarLayout from '../../../shared/layouts/SidebarLayout.vue';
import UsersViewAllLayout from '../../../shared/layouts/UsersViewAllLayout.vue';
import { defineComponent } from '@/plugins/composition';

export default defineComponent({
  name: 'MySchedulePage',
  components: {
    BaseRouter,
    UsersViewAllLayout,
    SidebarLayout,
  },
});
